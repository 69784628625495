import React, { useRef, useState } from "react";
import Button from "./atoms/Button";
import { ArrowRight } from "lucide-react";

const whyUsList = [
  {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill="currentcolor" opacity=".4" d="M56.9 309.7L1.3 441.8c-2.2 5.3-1.4 11.4 2.1 16s9.3 6.9 15 6l56.1-8.3 32.2 49.2c3.2 5 8.9 7.7 14.8 7.2s11-4.3 13.3-9.7l50.1-119c-3.9-.9-7.6-2.4-11-4.7L156 366.8c-6-3.9-13-5.8-20.1-5.4l-21.3 1.3c-13.2 .8-25.6-6.4-31.5-18.2l-9.6-19.1c-3.2-6.4-8.4-11.5-14.7-14.7l-1.8-.9zm142.3 73.5l50.1 119c2.3 5.5 7.4 9.2 13.3 9.7s11.6-2.2 14.8-7.2l32.2-49.2 56.1 8.3c5.7 .8 11.4-1.5 15-6s4.3-10.7 2.1-16L327.1 309.7l-1.8 .9c-6.4 3.2-11.5 8.4-14.7 14.7L301 344.5c-5.9 11.8-18.3 19-31.5 18.2l-21.3-1.3c-7.1-.4-14.2 1.5-20.1 5.4l-17.8 11.8c-3.4 2.3-7.2 3.8-11 4.7z"/><path fill="currentcolor" d="M210.2 5.5c-11-7.3-25.4-7.3-36.4 0L156 17.2c-6 3.9-13 5.8-20.1 5.4l-21.3-1.3c-13.2-.8-25.6 6.4-31.5 18.2L73.4 58.6C70.2 65 65 70.2 58.6 73.4L39.5 83c-11.8 5.9-19 18.3-18.2 31.5l1.3 21.3c.4 7.1-1.5 14.2-5.4 20.1L5.5 173.8c-7.3 11-7.3 25.4 0 36.4L17.2 228c3.9 6 5.8 13 5.4 20.1l-1.3 21.3c-.8 13.2 6.4 25.6 18.2 31.5l19.1 9.6c6.4 3.2 11.5 8.4 14.7 14.7L83 344.5c5.9 11.8 18.3 19 31.5 18.2l21.3-1.3c7.1-.4 14.2 1.5 20.1 5.4l17.8 11.8c11 7.3 25.4 7.3 36.4 0L228 366.8c6-3.9 13-5.8 20.1-5.4l21.3 1.3c13.2 .8 25.6-6.4 31.5-18.2l9.6-19.1c3.2-6.4 8.4-11.5 14.7-14.7l19.1-9.6c11.8-5.9 19-18.3 18.2-31.5l-1.3-21.3c-.4-7.1 1.5-14.2 5.4-20.1l11.8-17.8c7.3-11 7.3-25.4 0-36.4L366.8 156c-3.9-6-5.8-13-5.4-20.1l1.3-21.3c.8-13.2-6.4-25.6-18.2-31.5l-19.1-9.6c-6.4-3.2-11.5-8.4-14.7-14.7L301 39.5c-5.9-11.8-18.3-19-31.5-18.2l-21.3 1.3c-7.1 .4-14.2-1.5-20.1-5.4L210.2 5.5zM192 112a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>',
    svgSimple:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M128 64c0-35.3 28.7-64 64-64H352V128c0 17.7 14.3 32 32 32H512V448c0 35.3-28.7 64-64 64H221.3c1.8-5.1 2.7-10.5 2.7-16V416c1.3-.5 2.5-1 3.8-1.5c6.8-3 14.3-7.8 20.6-15.5c6.4-7.9 10.1-17.2 11.4-27.1c.5-3.6 .8-5.7 1.1-7.1c1.1-.9 2.8-2.3 5.6-4.5c19.9-15.4 27.1-42.2 17.5-65.5c-1.4-3.3-2.1-5.4-2.6-6.7c.5-1.4 1.2-3.4 2.6-6.7c9.5-23.3 2.4-50.1-17.5-65.5c-2.8-2.2-4.5-3.6-5.6-4.5c-.3-1.4-.6-3.6-1.1-7.1c-3.4-24.9-23-44.6-47.9-47.9c-3.6-.5-5.7-.8-7.1-1.1c-.9-1.1-2.3-2.8-4.5-5.6c-15.4-19.9-42.2-27.1-65.5-17.5c-2.6 1.1-5.1 2.3-6.6 3l-.1 .1V64zm384 64H384V0L512 128zM109.2 161.6L125 168c1.9 .8 4.1 .8 6.1 0l15.8-6.5c10-4.1 21.5-1 28.1 7.5l10.5 13.5c1.3 1.7 3.2 2.7 5.2 3l16.9 2.3c10.7 1.5 19.1 9.9 20.5 20.5l2.3 16.9c.3 2.1 1.4 4 3 5.2l13.5 10.5c8.5 6.6 11.6 18.1 7.5 28.1L248 285c-.8 1.9-.8 4.1 0 6.1l6.5 15.8c4.1 10 1 21.5-7.5 28.1l-13.5 10.5c-1.7 1.3-2.7 3.2-3 5.2l-2.3 16.9c-1.5 10.7-9.9 19.1-20.5 20.6L192 390.2V496c0 5.9-3.2 11.3-8.5 14.1s-11.5 2.5-16.4-.8L128 483.2 88.9 509.3c-4.9 3.3-11.2 3.6-16.4 .8s-8.5-8.2-8.5-14.1V390.2l-15.5-2.1c-10.7-1.5-19.1-9.9-20.5-20.6l-2.3-16.9c-.3-2.1-1.4-4-3-5.2L9.1 334.9c-8.5-6.6-11.6-18.1-7.5-28.1L8 291c.8-1.9 .8-4.1 0-6.1L1.6 269.2c-4.1-10-1-21.5 7.5-28.1l13.5-10.5c1.7-1.3 2.7-3.2 3-5.2l2.3-16.9c1.5-10.7 9.9-19.1 20.5-20.5l16.9-2.3c2.1-.3 4-1.4 5.2-3l10.5-13.5c6.6-8.5 18.1-11.6 28.1-7.5zM192 288A64 64 0 1 0 64 288a64 64 0 1 0 128 0z"/></svg>',
    question: "Elite Recognition And Access",
    answer:
      "Top Tier Roofing is a GAF Factory-Certified Contractor, providing assurance to you that the roof will be completed well and professionally.",
  },
  {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentcolor" opacity=".4" d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H416h32L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm176 32H0V416c0 35.3 28.7 64 64 64H296.2C271.1 449.6 256 410.5 256 368c0-91.8 70.3-167.2 160-175.3V192z"/><path fill="currentcolor" d="M432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm67.3-164.7l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L416 385.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>',
    svgSimple:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path fill="currentColor" d="M297 57l17-17L280 6.1 263 23 231 55l-17 17L248 105.9l17-17 32-32zM489 249l17-17L472 198.1l-17 17-32 32-17 17L440 297.9l17-17 32-32zm17-209L472 6.1 455 23 327 151l-17 17L344 201.9l17-17L489 57l17-17zM253.3 265.1L193.5 144 133.7 265.1 0 284.6l96.7 94.3L73.9 512l119.6-62.9L313 512 290.2 378.9l96.7-94.3L253.3 265.1z"/></svg>',
    question: "Top Quality Roofing Products",
    answer:
      "Our dedication to customer satisfaction is complemented by the class-leading roofing products we use for all our roofing projects",
  },
  {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentcolor" opacity=".4" d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/><path fill="currentcolor" d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z"/></svg>',
    svgSimple:
      '  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  fill="currentColor" d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>',
    question: "Licensed And Insured",
    answer:
      "Top Tier roofing is fully licensed and fully insured to give you a peace of mind and care that you deserve as a homeowner.",
  },
  {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentcolor" opacity=".4" d="M392.6 512c-5.4-9.4-8.6-20.3-8.6-32V352c0-23.7 12.9-44.4 32-55.4V272c0-48.8 31.2-90.3 74.7-105.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32v69.7c-.1 .9-.1 1.8-.1 2.8V472c0 22.1 17.9 40 40 40h16c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2H160h24c22.1 0 40-17.9 40-40V448 384c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64 24c0 22.1 17.9 40 40 40h.6z"/><path fill="currentcolor" d="M496 272v48h64V272c0-17.7-14.3-32-32-32s-32 14.3-32 32zm-48 48V272c0-44.2 35.8-80 80-80s80 35.8 80 80v48c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V352c0-17.7 14.3-32 32-32z"/></svg>',
    svgSimple:
      '  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path  fill="currentColor" d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>',
    question: "Industry Leading Warranties",
    answer:
      "Top Tier Roofing stands by the quality of our work with industry-leading warranties, covering both materials and craftsmanship, giving you peace of mind that your roofing system is built to last.",
  },
];

const Item = ({ item }) => {
  return (
    <li className="flex flex-col md:flex-row">
      {item?.svg && (
        <div
          className="w-10 h-10 md:w-12 md:h-12 flex items-center justify-center mb-4 md:mb-0 md:mr-8 text-brand-600 svg-full"
          dangerouslySetInnerHTML={{ __html: item?.svg }}
        />
      )}
      <div>
      <p className="font-display font-semibold text-lg md:text-xl mb-3 text-gray-800">{item?.question}</p>

      <div className="pb-5 leading-relaxed text-gray-600 font-display max-w-3xl pb-8">{item?.answer}</div></div>
    </li>
  );
};

const WhyUsSection = ({ title, classnames }) => {
  return (
    <div className={`relative py-16 bg-gradient-to-b from-brand-50 to-brand-50 relative shadow-xl overflow-hidden ${classnames}`}>
      <div className="max-w-7xl mx-auto  flex  justify-center">
        <div className="relative w-full max-w-7xl md:py-8 px-4 sm:py-6 sm:px-6 ">
        <p class="text-center md:text-left uppercase text-brand-600 font-semibold tracking-wide text-sm -mb-4">The Top Tier Difference</p>
          <h2 className=" text-3xl md:text-4xl font-display text-nuetral-900 font-bold text-center md:text-left mb-12">Why Choose Top Tier Roofing</h2>
   
          <ul className="grid grid-cols-2 lg:grid-cols-2 gap-6  ">
            {whyUsList.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </ul>
          <div className="border-t pt-10 md:flex md:justify-between font-display">
            <div className="text-center max-w-sm mx-auto mb-4 md:text-left md:max-w-5xl md:ml-0 md:mb-0 ">
            <h2 className=" text-2xl font-semibold  mb-2 ">Start a Project</h2>
            <p className="text-gray-500 font-normal">Get started with a FREE, no-obligation estimate. Your journey to a perfect roof starts here</p>
            
            </div>
          <Button color="orangeGradient" size="xl" className=" w-full md:w-auto" to="/contact">
                Get a FREE Estimate <ArrowRight size={18} />
              </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsSection;
