import React,{ useRef, useState } from "react"; 
import Button from "./atoms/Button"; 
import { ArrowRight } from "lucide-react"; 



const faqList = [ 
{
    question: "Should I Get a Roof Repair or Replace My Entire Roof?", 
    answer: "Top Tier offers roof repair services, new roof installation, and roof replacement. During our free estimates, we take a thorough inventory of the condition of your old roof. We look at your existing shingles or other roofing material, your roof's structure, and every other aspect of your roof's function, which is to protect your home. Before we replace a roof, we ensure that there are no other, more cost effective, options. This roof inspection is the only way we will know for sure if your roof can be repaired or must be replaced. We promise to give you the most honest assessment and exceptional workmanship, no matter which roofing options you take.",
},
{
    question: "Are You Licensed and Insured?",
    answer: "Not only are we locally owned and fully licensed, but as a best practice in the roofing industry, we are GAF factory-certified roofing contractors. Factory-certified roofing contractors are trained by GAF and are backed by a GAF warranty. That means we have passed an extensive training program and have access to the latest materials and technologies. In addition, we are required to be licensed and insured to install GAF roofing systems, a benefit that gets passed directly to you, our customer. If you are looking for a top-quality residential roofing contractor, you should consider hiring Top Tier Roofing. A GAF Contractor provides the highest quality roofing systems, and more than 93% of customers say they would recommend them to others.",
},
{
    question: "Will You Work with My Insurance Company?",
    answer: "We work directly with insurance companies on all of our roofing projects that are related to an insurance claim. We want to make residential roofing projects and commercial roofing projects as easy as possible for our clients. As a professional roofing company, we are familiar with the questions, processes, and requirements the insurance company may have.",
},
{
    question: "What Roofing Services Do You Offer?",
    answer: "We offer full roof replacement if you need a new roof, roof repairs, accent roofing service, minor repairs to roofing materials, repair storm damage, and so much more. We will give you a free estimate before we start working so that you know what to expect from the beginning. Along with roofing and gutters we can also handle siding, decks, windows and exterior painting. All your exterior needs will be done right the first time when choosing Top Tier Roofing to complete your project.",
},
{
    question: "How Will I Know What Is Going On with My Roofing Project?",
    answer: "You will be assigned a project manager who will keep you in the loop for the entire process, from the time we start construction to the time we wrap up with the insurance company.",
},
{
    question: "How Long Will My Roof Last?",
    answer: "The lifespan of your roof depends on the type of roof you have, the weather conditions in your area, and how well you maintain your roof. Most roofs will last between 20 and 30 years, but some roofs can last up to 50 years. If you have a slate roof, it can last up to 100 years. If you have a metal roof, it can last up to 75 years. If you have a wood shake roof, it can last up to 30 years. If you have an asphalt shingle roof, it can last up to 20 years. If you have a tile roof, it can last up to 50 years.",
},
{
    question: "How Much Does a New Roof Cost?",
    answer: "The cost of a new roof depends on the size of your roof, the type of roof you have, the materials you choose, and the roofing company you hire. The average cost of a new roof is between $5,000 and $10,000. If you have a slate roof, it can cost up to $30,000."
}];


const Item = ({ item }) => {
  const accordion = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <button
        className="relative flex gap-2 items-center w-full py-5 font-semibold text-left   text-base lg:text-xl text-neutral-100 bg-neutral-800  rounded-lg px-4"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        aria-expanded={isOpen}
      >
        <span
          className={`font-display font-semibold flex-1 ${isOpen ? "text-primary" : ""}`}
        >
          {item?.question}
        </span>
        <svg
          className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && "rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && "rotate-180 hidden"
            }`}
          />
        </svg>
      </button>

      <div
        ref={accordion}
        className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
        style={
          isOpen
            ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="pb-5 text-lg leading-8 text-neutral-400 font-display p-8 bg-neutral-800 max-w-3xl pb-8">{item?.answer}</div>
      </div>
    </li>
  );
};


const FAQ = ({ title, classnames }) => {

  return (
    <div className={`relative py-16 bg-gradient-to-br from-neutral-800 to-neutral-900 -300  overflow-hidden ${classnames}`}>
      
       <div className="max-w-7xl mx-auto  flex  justify-center">
              
            <div className="relative w-full max-w-7xl  md:py-12 px-4 space-y-4  sm:py-12 sm:px-6 ">
              {/* <p> Frequently Asked Questions</p> */}
              <h2 className="md:text-center text-4xl md:text-5xl font-display text-white font-extrabold">
              Frequently Asked Questions
              </h2>
              <p className="text-neutral-300 text-lg md:text-xl md:text-center font-display ">
              Home owners have questions, Top Tier Roofing provides answers.
              </p>
             
              <ul className="basis-1/2 !mt-8 grid md:grid-cols-2 gap-6">
          {faqList.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </ul>
              {/* <Button
              size="big"
              color="red"
                  to="/contact/"
                title={"Get Started with " + title}
                >
                Ask a Question<ArrowRight size={16} className="ml-2"/>
              </Button> */}
            </div>
           
      </div>
    </div>
  );
};
 

export default FAQ;
